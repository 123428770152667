/* eslint-disable react/destructuring-assignment,react/prop-types, no-underscore-dangle, react/jsx-one-expression-per-line, no-param-reassign */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { usePagination, useSortBy, useTable } from 'react-table';

import { withFirebase } from '../Firebase';
import { Box } from '../../style/basicStyle';
import toast from "../../utils/toast";

const TableStyles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns, data, initialState }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState
    },
    useSortBy,
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* eslint-disable-next-line no-nested-ternary */}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Box my={3}>
        <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageGoto = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(pageGoto);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20].map((pageSizeNew) => (
            <option key={pageSizeNew} value={pageSizeNew}>
              Show {pageSizeNew}
            </option>
          ))}
        </select>
      </Box>
    </>
  );
}

const newPerson = (user) => {
  return {
    username: user.username,
    displayName: user.displayName,
    email: user.email,
    uid: user.uid,
    admin: user.roles && user.roles.ADMIN && 'yes',
    emailVerified: user.emailVerified,
    lastSignInTime: user.lastSignInTime,
    creationTime: user.creationTime
  };
};

const columns = [
  {
    Header: 'Users',
    columns: [
      {
        Header: 'Username',
        accessor: 'username'
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ row: { original } }) => {
          const { email } = original;
          return (
            <a rel="noreferrer" target="_blank" href={`mailto:${email}`}>
              {email}
            </a>
          );
        }
      },
      {
        Header: 'Created',
        accessor: 'creationTime'
      },
      {
        Header: 'Last Signed In',
        accessor: 'lastSignInTime'
      },
      {
        Header: 'Admin',
        accessor: 'admin'
      },
    ]
  }
];

class UserList extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: []
    };
  }

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      const self = this;
      this._initFirebase = true;

      this.setState({ loading: true });

      this.props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          this.props.firebase.auth.currentUser
            // eslint-disable-next-line react/prop-types
            .getIdToken(/* forceRefresh */ true)
            .then(function getIdToken(idToken) {
              const values = { idToken };
              fetch('/.netlify/functions/getUsers', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
              })
                .then((result) => {
                  try {
                    const resultJSON = result.json();
                    resultJSON
                      .then(function processResult(r) {
                        if (result.status === 200) {
                          self.setState({
                            // eslint-disable-next-line react/no-unused-state
                            users: r.message,
                            loading: false,
                            idToken
                          });
                        } else {
                          console.log('getUsers request ERROR 1 - submitted values: ', values, ' | error is: ', r);
                          toast(`Error 1 downloading list - response code: ${JSON.stringify(r)}`);
                        }
                      })
                      .catch((error) => {
                        console.log('getUsers request ERROR 2 - submitted values: ', values, ' | error is: ', error);
                        toast(`Error 2 downloading list - error is: ${JSON.stringify(error.message)}`);
                      });
                  } catch (e) {
                    console.log('getUsers request ERROR 22 - submitted values: ', values, ' | res is: ', result, ' | error is: ', e);
                    toast(`Error 3 downloading list - error is: ${JSON.stringify(e.message)}`);
                  }
                })
                .catch((error) => {
                  console.log('getUsers request ERROR 3 - submitted values: ', values, ' | error is: ', error);
                  toast(`Error 4 downloading list - error is: ${JSON.stringify(error.message)}`);
                });
            })
            .catch(function getIdTokenError(error) {
              console.log('getUsers request - getIdTokenError - error is: ', error);
            });
        }
      });
    }
  };

  formatTable() {
    const { state } = this;
    const { users } = state;
    const length = users && users.length;
    const formatTableLevel = (depth = 0) => {
      return users.map((user) => {
        return {
          ...newPerson(user),
          subRows: length[depth + 1] ? formatTableLevel(depth + 1) : undefined
        };
      });
    };
    return formatTableLevel();
  }

  render() {
    const { loading } = this.state;

    return (
      <>
        <h2>Users</h2>
        {loading && <div>Loading ...</div>}
        <TableStyles>
          <Table
            columns={columns}
            data={this.formatTable()}
            initialState={{
              pageIndex: 0,
              pageSize: 10,
              sortBy: [
                {
                  id: 'lastSignInTime',
                  desc: true
                }
              ]
            }}
          />
        </TableStyles>
      </>
    );
  }
}

export default withFirebase(UserList);
